import React from 'react';
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import Img from "gatsby-image";

import './projectUnit.scss';

const Project = ({ item }) => {
  const link = `/projects/${item.slug}/`;
  return (
    <div className="projects-list__item wow fadeInUp">
      {item.list_image ? (
        <Link to={link} className="project__image">
          <Img fluid={item.list_image.childImageSharp.fluid} alt="" />
        </Link>
      ) : null}
      <div className="project__details">
        <h3 className="project__name">
          <Link to={link}>
            {item.name}
          </Link>
        </h3>
        <div className="project__description">
          {item.list_description}
        </div>
        <Link to={link} className="project__read-more">
          <FormattedMessage id="projects_list.read_more" />
          <span className="point-line" />
        </Link>
      </div>
    </div>
  )
};

export default Project;
