import React from 'react';
import { injectIntl, changeLocale, Link, FormattedMessage } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Header from '../components/layout/header';
import Footer from '../components/layout/footer';
import Counters from "../components/counters";
import Project from "../components/projectUnit";

import './projects.scss';

const QUERY = graphql`
query allProjects {
  allStrapiProject(filter: { active: { eq: true } }, sort: { order: DESC, fields: pub_date }) {
    edges {
      node {
        id
        name
        lang
        slug
        list_description
        selected
        list_image {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
`

const Projects = ({ intl }) => {
  const result = useStaticQuery(QUERY);
  const projects = result.allStrapiProject.edges.filter(n => n.node.lang === intl.locale).map((p) => p.node);

  React.useEffect(() => {
  });

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'title.projects' })} />
      <Header pageHeader={intl.formatMessage({ id: "title.projects" })} />
      <div className="page-projects">
        <div className="content">
          <div className="projects-list">
            {projects.map((project, i) => <Project key={project.slug} item={project} />)}
          </div>
        </div>
      </div>
      <Footer />
      <Counters />
    </Layout>
  )
}

export default injectIntl(Projects);
